<template>
  <div class="listing">
    <div class="main-list-wrapper">
      <div class="listing-banner">
        <div class="container">
          <div class="row _content align-items-center">
            <div class="col-md-7">
              <h2 v-if="content">{{ content.title }}</h2>
              <div v-else class="loading">Loading...</div>
              <p v-if="content" v-html="content.content"></p>
            </div>
            <div class="col-md-5">
              <img src="../assets/hand-player.png" class="d-block" alt="..." />
            </div>
          </div>
        </div>
      </div>
      <div class="filter-header">
        <div ref="events_head" class="container">
          <ul class="list-unstyled filter-list">
            <li>
              <input
                type="text"
                class="form-control select-button search-filter"
                v-model="search"
                @keyup="resetEventsFetch"
                placeholder="Search by name"
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="container">
        <h3>Upcoming Events</h3>
        <div v-if="eventLoader" class="loading">Loading...</div>
        <div class="row event-row" v-if="events">
          <EventCard v-for="event in events" :key="event.id" :event="event" />
          <div class="paging-wrapper py-3">
            <v-pagination
              v-model="eventPage"
              :pages="totalEventPages"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="eventPaginateUpdateHandler"
              v-if="totalEvents > 0"
            />
          </div>
          <EmptyList v-if="totalEvents == 0" messageText="No events found!" />
        </div>
      </div>
    </div>
    <div class="review-wrapper">
      <div class="experience-wrapper container">
        <Testimonial
          v-if="sortedTestimonials.length > 0"
          :sortedTestimonials="sortedTestimonials"
        />
      </div>
    </div>
    <CareerCounselling />
  </div>
</template>

<script>
import EventCard from "@/components/EventCard.vue";
import EmptyList from "@/components/EmptyList.vue";
import Testimonial from "@/components/Testimonial.vue";
import EventService from "@/services/EventService";
import CommonService from "@/services/CommonService";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import CareerCounselling from "@/components/CareerCounselling.vue";

export default {
  name: "ExploreEvents",
  components: {
    EventCard,
    VPagination,
    EmptyList,
    Testimonial,
    CareerCounselling,
  },
  data() {
    return {
      eventLoader: true,
      // Initial page number for event if any
      eventPage: 1,
      eventsPerPage: 12,
      currentEventPage: 1,
      totalEventPages: 0,
      totalEvents: 0,
      events: [],
      colval: "col-md-4",
      sortedTestimonials: [],
    };
  },
  mounted() {
    // Note:- Append the pixel code script for this page to head tag only for production env
    if (process.env.NODE_ENV === "production") {
      let pixelCodeForEvents = document.createElement("script");
      pixelCodeForEvents.innerHTML =
        "!function(f,b,e,v,n,t,s)" +
        "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?" +
        "n.callMethod.apply(n,arguments):n.queue.push(arguments)};" +
        "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';" +
        "n.queue=[];t=b.createElement(e);t.async=!0;" +
        "t.src=v;s=b.getElementsByTagName(e)[0];" +
        "s.parentNode.insertBefore(t,s)}(window, document,'script'," +
        "'https://connect.facebook.net/en_US/fbevents.js');" +
        "fbq('init', '216933973926923');" +
        "fbq('track', 'PageView');";
      document.head.appendChild(pixelCodeForEvents);
    }
  },
  created() {
    this.fetchContent();
    this.loadEvents();
    this.fetchTestimonials();
  },
  methods: {
    resetEventsFetch() {
      this.eventPage = 1;
      this.currentEventPage = 1;
      this.loadEvents();
    },
    async loadEvents() {
      let params = {
        page: this.currentEventPage,
        search: this.search,
        per_page: this.eventsPerPage,
      };
      await EventService.getEvents(params)
        .then((response) => {
          this.eventLoader = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.events = response.data.events.data;
            // works only when totalEventPages should be the last_page value
            this.totalEventPages = response.data.events.last_page;
            this.currentEventPage = response.data.events.current_page;
            this.totalEvents = response.data.events.total;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          // console.log(this.events);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    eventPaginateUpdateHandler(selectedPage) {
      if (this.currentEventPage !== selectedPage) {
        this.currentEventPage = selectedPage;
        this.scrollToElement(this.$refs.events_head);
        this.loadEvents();
      }
    },
    fetchContent() {
      let params = {
        slug: "explore",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    scrollToElement(refId) {
      const el = refId;
      if (el) {
        el.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/style/listing.scss";
</style>
